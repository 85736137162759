// COLORS
$primary_color: #23A455;
$primary_dark_color: #073a68;

$bg_gradient_light_color: #1c75bc;
$bg_gradient_dark_color: #2b3990;

$secondary_color: #fbb603;

$light_text_color: #fff;
$dark_text_color: #58595b;
$dark_text_color_1: #939598;
$dark_text_color_2: #b3b4b7;
$orange_color: #e2a552;
$orange_color_bold: #df9d42;
$orange_color_bold_2: #e67817;

$orange_color_line: #fbbe20;

$grey_color: #f1f2f2;
$dark_grey_color: #d1d3d4;
$dark_grey_color_2: #bcbec0;

// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

$background_color: #fff;
$primary_dark_color: #6D7381;
$primary_light_color: #F7845C;
$primary_green: #23A455;

$grey_light_color: #6E7793;
$red_color: #FC4D5C;
$green_color: #00C28E;
$green_light_color: #7ac286;
$yellow_color: #FAB416;
$orange: #F7845C;

$light_gray: #D8D8D8;
$light_gray_2: #979797;
$light_gray_3: #979797;

$gray: #6D7381;
$dark_gray: #333;
