@import "./variables";

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin verticalCenter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


@mixin horizontalCenter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


@mixin backgroundGradient {
    background: $bg_gradient_light_color !important;
    background: linear-gradient(120deg, $bg_gradient_light_color 60%, $bg_gradient_dark_color 100%) !important;
}


@mixin containerWidth {
    width: 117rem;
}

@mixin containerWidth1024 {
    width: 95rem;
}

@mixin containerWidth768 {
    width: 85rem;
}

@mixin containerWidth540 {
    width: 65rem;
}

@mixin containerWidth425 {
    width: 50rem;
}

@mixin containerWidth375 {
    width: 43rem;
}

@mixin containerWidth320 {
    width: 38rem;
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };    //1800
    }
}